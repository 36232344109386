import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiConnectionService } from '../../services/core/api-connection.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigService } from '../../services/core/config.service';
import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { ModalContentComponent } from '../../modals/modal-content/modal-content.component';

@Component({
	selector: 'app-lessons-form',
	templateUrl: './lessons-form.component.html',
	styleUrls: ['./lessons-form.component.scss']
})
export class LessonsFormComponent implements OnInit {
	error: boolean = false;
	item: any = {};
	chapters: any = [];
	errors: any = [];
	mutateForm;
	modal;
	studyLevels: any = [];
	contents = [];
	errormessage = false;
	successmessage = false;
	changepassword = false;
	lessonTypes: any = [];
	classrooms: any = [];
	imageSource: string = '';

	constructor(
		public apiConnectionService: ApiConnectionService,
		private route: ActivatedRoute,
		private config: ConfigService,
		private modalService: NgbModal,
		private location: Location,
		private ngxLoader: NgxUiLoaderService
	) { }

	ngOnInit(): void {
		let self = this;

		this.fetchItem();

		this.apiConnectionService.read('chapters').then((response) => {
			self.chapters = response;
		});

		this.lessonTypes = this.config.get('lesson_types');
		this.studyLevels = this.config.get('study_levels');
	}

	get frontUrl() {
		return this.config.get('front_url');
	}

	checkDescriptionLength() {
		if (this.item['description'].length > 245) {
			this.item['description'] = this.item['description'].substring(0, 244);
		}
	}

	addLessonContent() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;

		let postData = {
			'lessonId': this.item['id']
		};

		self.ngxLoader.start();

		this.apiConnectionService.save('lessons/saveLessonContent', postData).then((response) => {
			self.ngxLoader.stop();
			self.fetchLessonContents();
		}).catch(function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

	deleteLessonContent(lessonContent) {
		let self = this;
		this.ngxLoader.start();
		this.apiConnectionService.delete('lessons/deleteLessonContent/' + lessonContent.id + '').then((response) => {
			self.fetchItem();
			self.ngxLoader.stop();
		}).catch(function (error) {
			self.ngxLoader.stop();
		});
	}

	confirmDeleteLessonContent(index) {
		let self = this;
		let lessonContent = this.contents[index];

		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je dit niveau wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				if (lessonContent && lessonContent['id'] != "") {
					self.deleteLessonContent(lessonContent);
				} else {
					self.contents.splice(index, 1);
				}
			}
		});
	}

	fetchItem() {
		let id = this.route.snapshot.paramMap.get('id');

		let self = this;
		this.ngxLoader.start();
		this.apiConnectionService.read('lessons/details/' + id + '').then((response) => {
			self.item = response;
			self.ngxLoader.stop();
			self.fetchLessonContents();
		});
	}

	fetchLessonContents() {
		let id = this.item['id'];

		let self = this;
		this.apiConnectionService.read('lessons/contents/' + id + '').then((response) => {
			self.contents = response['contents'];
		});
	}

	handleImageChange(e, index) {
		var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
		var pattern = /image-*/;
		var reader = new FileReader();
		if (!file.type.match(pattern)) {
			alert('invalid format');
			return;
		}

		reader.onload = this._handleImageReaderLoaded.bind(this, index);
		reader.readAsDataURL(file);
	}

	_handleImageReaderLoaded(index, e) {
		let reader = e.target;
		this.contents[index]['imageSource'] = reader.result;
	}

	triggerImageSelect(event, index) {
		let element: HTMLElement = document.getElementById('image-select-' + index) as HTMLElement;
		element.click();
	}

	syncLesson() {
		let self = this;

		this.modal = this.modalService.open(ModalContentComponent, {
			backdrop: 'static',
			keyboard: false
		});

		this.apiConnectionService.read('lessons/syncSingle/' + self.item['id'] + '',).then((response) => {
			self.modal.componentInstance.message = '<i class="fas fa-check" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Les gesynced!';
			self.fetchItem();
		});

		this.modal.componentInstance.message = '<i class="fas fa-circle-notch fa-spin" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Les aan het syncen';
		this.modal.componentInstance.title = 'Sync les';
		this.modal.result.then((result) => {
		});
	}

	save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;

		let postData = self.item;
		if (this.imageSource != '') {
			// postData['imageSource'] = this.imageSource;
		}

		postData['lessonContents'] = this.contents;

		this.apiConnectionService.save('lessons/save', postData).then((response) => {
			self.item = response;
			self.ngxLoader.stop();
			self.successmessage = true;
		}).catch(function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

	backLink() {
		this.location.back();
	}

	deleteThumb(lesson, index) {
		let self = this;
		this.ngxLoader.start();
		this.apiConnectionService.read('lessons/clearContentThumb/' + self.contents[index]['id'] + '').then((response) => {
			self.fetchItem();
			self.ngxLoader.stop();
		}).catch(function (error) {
			self.ngxLoader.stop();
		});
	}

	confirmDeleteThumb(lesson, index) {
		let self = this;
		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je de afbeelding wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				self.deleteThumb(lesson, index);
			}
		});
	}
}
